<template>
  <div class="auth-wrapper auth-v1 align-start py-0">
    <div class="auth-inner">
      <v-card-text>
        <v-row class="px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-space-between align-center"
          >
            <div class="d-flex justify-space-between align-center">
              <v-img
                :src="appLogoTitle"
                max-width="120px"
                alt="logo"
                contain
                class="me-3 cursor-pointer"
                @click="$router.push('/')"
              ></v-img>
            </div>

            <div>
              <v-btn
                class="ma-0 py-2 px-1 no-bg-hover font-weight-regular"
                style="
                  text-transform: inherit;
                  min-width: 60px;
                "
                text
                color="secondary"
                @click="defaultDownloadAppByOS"
              >
                {{ t('videos.download_app') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="mt-14 mb-5 py-1 px-2 d-flex justify-center align-center"
          >
            <v-avatar
              :color="circle.circle_logo ? '' : '#090909 !important'"
              :class="circle.circle_logo ? '' : 'v-avatar-light-bg primary--text'"
              size="150"
              rounded="lg"
            >
              <v-img
                v-if="circle.circle_logo"
                :src="circle.circle_logo"
                :aspect-ratio="1"
                cover
              ></v-img>
              <v-icon
                v-else
                class="pa-8"
                size="180"
                rounded="lg"
                color="primary"
              >
                {{ icons.mdiAccountGroup }}
              </v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="mb-4 px-4 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <h3
              v-if="!invalidInvitation"
              class="white--text"
            >
              {{ circle.circle_name }}
            </h3>
            <h3
              v-if="invalidInvitation"
              class="white--text"
            >
              {{ t('videos.invalid_invitation') }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <span
              v-if="!hasException && !invalidInvitation"
              class="white--text"
            >
              {{ t('videos.invited_you') }}
            </span>
            <span
              v-if="hasException"
              class="white--text"
            >
              {{ t('videos.ask_group' ) }}
            </span>
          </v-col>
        </v-row>

        <v-row class="my-5 px-4 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <v-btn
              class="text-capitalize"
              style="height: 42px !important"
              color="secondary"
              dark
              rounded
              block
              :disabled="hasException || invalidInvitation"
              @click="onWatch"
            >
              <span v-if="!hasException && !invalidInvitation">
                {{ t('videos.accept_invitation') }}
              </span>
              <span v-if="hasException">
                {{ t('videos.invitation_expired') }}
              </span>
              <span v-if="invalidInvitation">
                {{ t('videos.invalid_invitation') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="ma-0 mb-4 mx-6 px-0 py-5"
          style="borderTop: 1px solid #222222"
        >
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <h3 class="gray--text text-sm font-weight-regular">
              {{ t('videos.dont_have') }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <v-btn
              class="ma-0 pa-0 px-1 no-bg-hover font-weight-regular"
              style="
                text-transform: inherit;
                min-width: 60px;
                height: 12px !important;
              "
              text
              color="secondary"
              @click="defaultDownloadAppByOS"
            >
              {{ t('videos.download_app') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <!-- TODO use download component -->
    <v-dialog
      v-model="isOpen"
      width="350px"
    >
      <v-card class="py-5">
        <v-icon
          class="absolute-close"
          @click="() => isOpen = false"
        >
          {{ icons.mdiClose }}
        </v-icon>

        <v-card-text class="pt-8 pb-0">
          <v-card-title class="justify-center">
            {{ t('videos.download_app_fanaty') }}
          </v-card-title>
          <v-card-subtitle class="justify-center text-center pb-0">
            {{ t('videos.continue_download') }}
          </v-card-subtitle>
        </v-card-text>

        <v-card-text>
          <v-row class="my-5 px-0 py-1 ma-0">
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-center align-center"
            >
              <v-btn
                class="text-xs text-capitalize"
                color="secondary"
                dark
                rounded
                block
                @click="defaultDownloadAppByOS"
              >
                {{ t('videos.download_app') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            class="ma-0 mx-2 px-0 py-5"
            style="borderTop: 1px solid #222222"
          >
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-center align-center"
            >
              <h3 class="gray--text text-sm font-weight-regular">
                {{ t('videos.need_support') }}
              </h3>
            </v-col>
            <v-col
              cols="12"
              class="py-1 px-2 d-flex justify-center align-center"
            >
              <a
                href="mailto:support@fanaty.com?Subject=Support"
                class="text-mail font-weight-regular"
              >
                support@fanaty.com
              </a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, onMounted } from '@vue/composition-api'
import {
  useRouter,
  isAndroid,
  isIOS,
  openTab,
  defaultDownloadAppByOS,
} from '@core/utils'
import { avatarText } from '@core/utils/filter'
import { useUtils } from '@core/libs/i18n'
import themeConfig from '@themeConfig'
import { asyncLoading } from 'vuejs-loading-plugin'
import { mdiClose, mdiAccountGroup } from '@mdi/js'
import { getVideoCircle } from '@api'

export default {
  setup() {
    const { route } = useRouter()
    const { t } = useUtils()

    const deepLinkGroup = ref('fanaty://circle?circle_id=')

    const circle = ref({
      circle_id: null,
      circle_logo: null,
      circle_name: null,
    })
    const circleId = ref(null)
    const refCode = ref(null)
    const hasException = ref(false)
    const invalidInvitation = ref(false)
    const timerWatch = ref(null)
    const isOpen = ref(false)

    const fiveSecondPromise = () => new Promise(resolve => {
      setTimeout(resolve, 5000)
    })

    const onWatch = () => {
      isOpen.value = true
      if (isAndroid() || isIOS()) {
        if (isIOS()) openTab(`${deepLinkGroup.value}${circleId.value}`)
        else window.location = `${deepLinkGroup.value}${circleId.value}`

        asyncLoading(fiveSecondPromise()).then().catch()
        clearTimeout(timerWatch.value)
        timerWatch.value = setTimeout(() => {
          isOpen.value = true

          setTimeout(() => {
            isOpen.value = false
          }, 10000)
        }, 5000)
      } else openTab('https://www.fanaty.com')
    }

    onMounted(async () => {
      refCode.value = route.value.params.code
      const resp = await getVideoCircle(refCode.value)
      circle.value = resp.data
      circleId.value = resp.data.circle_id

      if (!resp.ok) {
        if (resp.message.code === 'CL002') hasException.value = true
        if (resp.message.code === 'CL003') invalidInvitation.value = true
      }
    })

    return {
      circle,
      circleId,
      refCode,
      hasException,
      invalidInvitation,
      isOpen,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appLogoTitle: themeConfig.app.logoTitle,

      openTab,
      defaultDownloadAppByOS,
      onWatch,
      avatarText,

      t,

      icons: {
        mdiClose,
        mdiAccountGroup,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';
</style>
<style lang="scss" scoped>
  .absolute-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #222222;
    color: #ffffff6b;
    border-radius: 50px !important;
    padding: 2px;
  }

  .border-img {
    border-radius: 10px;
  }

  .text-mail {
    color: #FF3232;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.01875rem;
    text-decoration: none;
    white-space: nowrap;
  }
</style>
